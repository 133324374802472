import { Loader } from 'app/components/loader'
import { useCalculateFontSize } from 'app/redux/hooks/use-calculate-font-size'
import { useDim } from 'app/redux/hooks/useDim'
import { DripsyProvider, makeTheme } from 'dripsy'
import * as raf from 'raf'
import { useEffect, useState } from 'react'
import { Text } from 'react-native'
import { Platform, View } from 'react-native'
import { useSelector } from 'react-redux'
// import * as Permissions from 'expo-permissions';
raf.polyfill()

const webFont = (font: string) =>
  Platform.select({
    web: `${font}, Arial, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Inter-serif`,
    default: font,
  })

export function Dripsy({ children }: { children: React.ReactNode }) {
  const getFontSize = useCalculateFontSize()
  const { height }=useDim()
  const [reloaded, setReload] = useState(false)
  useEffect(() => {
    setReload(true)
  }, [])
  const theme = makeTheme({
    fonts: {
      root: 'Arial',
    },
    text: {
      btnText: getFontSize(15, 12),
      sectionTitle: getFontSize(24, 15),
      sectionSubTitle: getFontSize(17, 13),
    },
    customFonts: {
      InterRegular: {
        bold: webFont('InterRegular'),
        default: webFont('InterRegular'),
      },
      Inter: {
        bold: webFont('Inter'),
        default: webFont('Inter'),
      },
    },
    colors: {
      $primary: '#CE7335',
      $secondary: '#2a1c19',
      $textColor: '#2F3443',
      $textColorTwo: '#2f3443',
      $white: '#fff',
      $black: '#000',
      $pColor: '#888',
      $red: '#eb0519',
      $gray: '#D9D9D9',
      $yellow: '#FFCB05',
      $disabled: '#99999033',
      // new theme color code added for new pages
      $tertiary: '#F47979',
      $fontGrey: '#000000',
      $darkSilver: '#646977',
      //exam-genie color code for black theme
      $newBlack: '#343434',
      $darkBlue: '#21005B',
      $textBlue: '#07294D',
      $skyBlue: '#E7EBFB',
      $lightGrey: '#dddd',
      $cultured: '#f3f1fa',
      $brightGrey: '#EAEAEA',
      // Exam-adda
      $aquaSpring: '#EDF9F5',
      $jaggedIce: '#C4E7DB',
      $paleTeal: '#66C7A5',
      $merino: '#F7EEE5',
      $almond: '#EFDDCD',
      $whiskey: '#DCA06A',
      $titanWhite: '#F1F0FE',
      $geyser: '#DBD9EF',
      $blueBell: '#A193CE',
      $desertStorm: '#F8F8F8',
    },
    shadows: {
      md: {
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
      },
    },
    buttons: {
      label: {
        fontWeight: '600',
        fontSize: 3,
        textAlign: 'center',
        fontFamily: 'Inter',
      },
      primary: {
        bg: '$primary',
        fontFamily: 'Inter',
        borderColor: '$primary',
        label: {
          color: '#fff',
        },
      },
      secondary: {
        bg: '$secondary',
        fontFamily: 'Inter',
        borderColor: '$secondary',
        label: {
          color: '#fff',
        },
      },
    },
  })
  const { loaded: _loaded } = useSelector((state: any) => ({
    loaded: state.loaded,
    clientConfig: state.clientConfig,
    user: state.user,
  }))

  return _loaded && reloaded? (
    <DripsyProvider
      theme={theme as any}
      // this disables SSR, since react-native-web doesn't have support for it (yet)
      ssr={false}
    >
      {children}
    </DripsyProvider>
  ) : (
    <>
      <View
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height,
          width:'100%',
        }}
      >
        <Loader />
      </View>
    </>
  )
}
