import reducer from './reducers';
import {thunk, ThunkMiddleware} from 'redux-thunk';
import axios, { AxiosRequestConfig } from 'axios';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { config } from './config';
import { makeUseAxios, Options, UseAxiosResult } from 'axios-hooks';
import asyncDispatchMiddleware from './middleware/async-dispatch';
import { Platform } from 'react-native';
import { getAsyncStorageData, getCookie } from 'app/util/helper';
import { SET_TOKEN } from './constants';
import { configureStore } from '@reduxjs/toolkit';
import navigateOnSuccessMiddleware from './middleware/navigate-on-success'



export const client: any = {
  default: {
    client: axios.create({
      baseURL: config.baseUrl,
    }),
  }
};

axios.interceptors.request.use((req) => {
  req.baseURL = config.baseUrl;
  req.timeout = 60000;
  return req;
});

export const getToken = async () => {
  if (!store) {
    return;
  }
  if (store.getState().token) {
    store.dispatch({
      type: 'GLOBAL_LOADED',
    });
    return store.getState().token;
  } else {
    if (Platform.OS === 'web') {
      const token = getCookie(config.authTokenName);
      if (token) {
        store.dispatch({
          type: SET_TOKEN,
          payload: token || '',
        });
      } else {
        store.dispatch({
          type: 'GLOBAL_LOADED',
        });
      }
      return token;
    } else {
      const token = await getAsyncStorageData(config.authTokenName);
      if (token) {
        store.dispatch({
          type: SET_TOKEN,
          payload: token || '',
        });
      } else {
        store.dispatch({
          type: 'GLOBAL_LOADED',
        });
      }
      return token;
    }
  }
};

// Get Token from cookie or async storage
// if found,
// 1. set token in state - by calling setToken action
// 2. call me api by using redux action called USER_REFRESH -

const axiosMiddlewareConfig = {
  interceptors: {
    request: [
      function (
        {}: any,
        req: {
          baseURL: any;
          timeout: number;
          headers: {  Authorization?: string; credentials?: string  };
        }
      ) {
        req.timeout = 60000;
        const token = store.getState().token;

        if (token) {
          req.headers.Authorization = `Bearer ${token}`;
        } else {
          delete req.headers['Authorization'];
        }
        req.headers.credentials = 'include';
        // req.headers.Authorization = `Bearer ${'eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJzdWIiOiI1ZmU0NzBhNjcyYzczNTAwMmQ5YTkyYjgiLCJmaXJzdE5hbWUiOiJMZW5pbiIsImxhc3ROYW1lIjoiTW9oYXBhdHJhIiwicm9sZSI6NywidXNlcm5hbWUiOiJsZW5pbl9tb2hhIiwiaWF0IjoxNjU5NjMwNTI0LCJhdWQiOiJodHRwczovL2FjY291bnRzLmxlY3R1cmVzbm90ZXMuY29tIiwiaXNzIjoiZmVhdGhlcnMiLCJqdGkiOiJkM2RjZjZmMS04NjE2LTQ3NzYtODU3Yi04NzI4Y2IxMGM0ZWYifQ.6I37PfZhtefBiptNJKMLpxRvGeg2bd4-1eOeRupceHk'}`
        return req;
      },
    ],
    response: [
      {
        success: function ({}, res: any) {
          if (res.config && res.config.method === 'get') {
            if (res.data.data && res.data.total && res.data.limit) {
              const { total, limit, skip = 0, ...othersData } = res.data;
              res.data = {
                ...othersData,
                hasMore: total > limit + skip,
                total,
                limit,
                skip,
              };
            }
          }
          store;
          return Promise.resolve(res);
        },
        error: function ({}, error: { response: { status: number } }) {
          if (error && error.response && error.response.status === 401) {
            // unauthorized - redirect to login
          }
          return Promise.reject(error);
        },
      },
    ],
  },
};

export const store = configureStore({
  reducer,
  preloadedState: {},
  middleware: () => [
    thunk,
    multiClientMiddleware(client, axiosMiddlewareConfig),
    asyncDispatchMiddleware as any,
    navigateOnSuccessMiddleware as any,
  ] as any,
});
getToken();

export function makeUseAxiosHook(clientName: string, options?: Options) {
  const axiosClient = (client[clientName] || client['default']).client;
  return function useAxiosHook(
    axiosConfig: string | AxiosRequestConfig<any>,
  ): UseAxiosResult<any, any, any> {

    const axiosInstance = axiosClient.create(); // Create a new axios instance

    // Add request and response interceptors to the new instance
    axiosInstance.interceptors.request.use((req: any) => {
      if (
        typeof axiosConfig === 'object' &&
        axiosConfig.params &&
        Object.entries(axiosConfig.params).length
      ) {
        const q = req.url.split('?');
        const existingQ = q.length === 2 ? q[1].split('&') : [];
        const queryString = Object.entries(axiosConfig.params)
          .map((e) => (e[1] ? `${e[0]}=${e[1]}` : ''))
          .filter((e) => e != '' && !existingQ.includes(e))
          .join('&');

        if (queryString)
          req.url = req.url + (req.url.includes('?') ? '&' : '?') + queryString;

        delete axiosConfig.params;
      }
      if (store.getState().token) {
        req.headers.Authorization = `Bearer ${store.getState().token}`;
      } else {
        if (req?.headers) delete req?.headers?.Authorization;
      }

      const query = req.url.split('?');
      const str: string[] = [];
      if (query.length === 2) {
        const queryList = query[1].split('&');
        for (const index in queryList) {
          const [key, value] = queryList[index].split('=');
          if (typeof value === 'boolean' || value === 0 || value) {
            str.push(`${key}=${encodeURI(value)}`);
          }
        }
      }
      req.url = `${query[0]}${str.length ? '?' + str.join('&') : ''}`;

      return req;
    });

    axiosInstance.interceptors.response.use((res: any) => {
      if (res.config && res.config.method === 'get') {
        if (res.data.data && res.data.total && res.data.limit) {
          const { total, limit, skip = 0, ...othersData } = res.data;
          res.data = {
            ...othersData,
            hasMore: total > limit + skip,
            total,
            limit,
            skip,
          };
        }
      }
      return res;
      // }, (error: any) => {
      //   if(error.name === 'CanceledError') {
      //     return {};
      //   }
      //   Promise.reject(error);
    });

    const result = makeUseAxios({
      axios: axiosInstance, // Use the new axios instance
    })(axiosConfig, options);

    return result;
  };
}

export function useCustomAxiosHook() {
 
  const axiosInstance = (client['default']).client;
  axiosInstance.interceptors.request.use(async (req: any) => {
    // Request interceptor logic
      
    if (store.getState().token) {
      req.headers.Authorization = `Bearer ${store.getState().token}`;
    } else {
      if (req?.headers) delete req?.headers?.Authorization;
    }

    return req;
  });

  axiosInstance.interceptors.response.use((res: any) => {
    // Response interceptor logic
    return res;
  });

  return axiosInstance;
}