import { combineReducers } from 'redux';
import user from './user.reducers';
import token from './token.reducer';
import toasts from './toasts';
import loaded from './loaded.reducer';
import notification from './notification.reducer';
import globalStore from './global.reducer';
import clientConfig from './app-confg.reducer';
import { LOGOUT_SUCCESS } from '../constants';
import userLogout from '../hooks/user-logout';

const appReducer = combineReducers({
  user,
  token,
  toasts,
  loaded,
  notification,
  globalStore,
  clientConfig
});

const rootReducer = (state: any, action: any) => {   
  // Clear all data in redux store to initial.
  if(action.type === LOGOUT_SUCCESS || action.type === 'LOGOUT_FAIL'){
     userLogout()
     .then(() => {
        // if(Platform.OS != 'web')
        //   BackHandler.exitApp();
     });
     state = {
      ...state, 
      user: null,
      token: ''
     };
    }
  
  
  return appReducer(state, action);
};

export default rootReducer;