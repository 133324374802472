import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface ObjectDetectionContextState {
  pageStatus: 'upload-screen' | 'object-list' | 'upload-reference-screen' | 'reference-object-list' | 'result-screen';
  originalFile: any;
  imageObjectList: any[];
  referenceFile: any;
  referenceObjectList: any[];
  originalImagePoint: any;
  referenceImagePoint: any;
  resultObj?: any;
  originalImageMask?: any;
  referenceImageMask?: any;
}

export const ReplaceContext = createContext<{
  replaceState: ObjectDetectionContextState;
  setReplaceState: Dispatch<SetStateAction<ObjectDetectionContextState>>;
  replaceStateReset: () => void;
}>({
  replaceState: {
    pageStatus: 'upload-screen',
    originalFile: {},
    imageObjectList: [],
    referenceFile: {},
    referenceObjectList: [],
    originalImagePoint: {},
    referenceImagePoint: {},
    resultObj: undefined,
    originalImageMask: [],
    referenceImageMask: [],
  },
  setReplaceState: () => {},
  replaceStateReset: () => {},
});

interface ObjectDetectionContextProviderProps {
  children: ReactNode;
}

export const ReplaceContextProvider: React.FC<ObjectDetectionContextProviderProps> = ({ children }) => {
  const initialState: ObjectDetectionContextState = {
    pageStatus: 'upload-screen',
    originalFile: {},
    imageObjectList: [],
    referenceFile: {},
    referenceObjectList: [],
    originalImagePoint: {},
    referenceImagePoint: {},
    resultObj: undefined,
    originalImageMask: [],
    referenceImageMask: [],
  };

  const [replaceState, setReplaceState] = useState<ObjectDetectionContextState>(initialState);

  const replaceStateReset = () => {
    setReplaceState(initialState);
  };

  return (
    <ReplaceContext.Provider
      value={{
        replaceState,
        setReplaceState,
        replaceStateReset,
      }}
    >
      {children}
    </ReplaceContext.Provider>
  );
};
