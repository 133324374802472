import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_REFRESH_SUCCESS,
  USER_REFRESH_FAIL,
  UPDATE_USER_DATA,
  UPDATE_GLOBAL_STORE_DATA,
} from 'app/redux/constants';
import { Platform } from 'react-native';
import { setAsyncStorageData } from '../../util/helper';

//set from cookie or async storage
const setUser = async (user: any) => {
  if (Platform.OS === 'web') {
    await setAsyncStorageData('user', JSON.stringify(user));
  }
};

const initialState = null;

const loggedInUser = (
  state = initialState,
  action: {
    type: any;
    payload: {
      data: any
      locale?:string
    };
    asyncDispatch: (arg0: {
      type: string;
      payload?: { client?: string; request?: { url: string; method: string };
      locale?:string;
    };
    }) => void;
    error: { response: any; data: any };
  }
) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      setUser(action.payload.data.users);
      return action.payload.data.users;
    }
    case LOGIN_FAIL: {
      // console.log('action.error',action.error);
      return {
        error:{
          message:action.error.response?.data?.message ||
          'The password you entered is incorrect. Please try again',
          fieldKey: action.error.response?.data?.data?.name||'',
        }
      };
    }
    case USER_REFRESH_SUCCESS: {
      setTimeout(
        () =>{
        action.asyncDispatch({
          type: 'GLOBAL_LOADED',
        });
        action.asyncDispatch({
          type: UPDATE_GLOBAL_STORE_DATA,
          payload: {
             locale:action?.payload?.data?.language || 'en'
          },
        })
      },
        5
      );
      return action.payload.data;
    }
    case USER_REFRESH_FAIL: {
      if(action.error.data === 'Network Error'){
        return state;
      }
      setTimeout(() => {
        action.asyncDispatch({
          type: 'GLOBAL_LOADED',
        });
      }, 10);

      return null;
    }
    case UPDATE_USER_DATA: {
      return { ...(state ? state : {}), ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export default loggedInUser;

// import { Button } from "app/components/button";
// import { Space } from "app/components/space";
// import { TextField } from "app/components/text-field";
// import Layout from "app/features/layout";
// import { Text, TextInput, View, useSx } from "dripsy";
// import React, { useMemo, useState } from "react";
// import { Link } from 'app/components/link'
// import { useRouter } from "solito/router";
// import { isValidEmail } from "app/util/helper";
// import { createParam } from "solito";
// import { makeUseAxiosHook } from "app/redux/store";
// import { useToast } from "app/redux/hooks/use-toast";
// import { useDispatch } from "react-redux";
// import { SET_TOKEN } from "app/redux/constants";


// const { useParam } = createParam<{ type: string }>()

// export const ForgotPasswordScreen = ({
// }) => {
//   const [type] = useParam('type');
//   const dispatch = useDispatch();
//   const sendMessage = useToast();

//   const Router = useRouter();
//   const [fieldState, setFieldState] = useState<any>(type ? 'changepassword' : 'email');
//   const [loading, setLoading] = useState<boolean>(false)
//   const { push, replace } = Router;
//   const [newToken, setNewToken] = useState(null)
//   const sx = useSx()
//   const [selectedEditObj, setSelectedEditObj] = useState({
//     email: '',
//     otp: '',
//     password: '',
//     confirmPassword: ''
//   });

//   const [errorObj, setErrorObj] = useState<any>({})

//   const [{ data: verifyEmail, error: verifyEmailError, loading: otpLoading }, onSendOtp] = makeUseAxiosHook(
//     'default',
//     {
//       manual: true,
//     }
//   )({
//     url: '/api/v1/send-otp',
//     method: 'POST',
//   });





//   const [{ data: verifyPasswordData, error: verifyError, loading: verifyLoading }, verifyPassword] = makeUseAxiosHook(
//     'default',
//     {
//       manual: true,
//     }
//   )({
//     url: '/api/v1/verify-password',
//     method: 'POST',
//   });
//   const [{ data: changePasswordData, }, onChangePassword] = makeUseAxiosHook(
//     'default',
//     {
//       manual: true,
//     }
//   )({
//     url: '/api/v1/change-password',
//     method: 'POST',
//   });


//   const updateValue = (key: string, value: any) => {
//     setSelectedEditObj({ ...selectedEditObj, [key]: value })
//   }
//   const validateForm = (type: string) => {
//     let errorList: any = {}
//     if (type === 'email') {
//       if (!selectedEditObj.email) {
//         errorList.email = 'Please enter your Email'
//       }
//       if (selectedEditObj.email && !isValidEmail(selectedEditObj.email)) {
//         errorList.email = 'Please provide a valid email address.'
//       }
//     }

//     if (type === 'otp') {
//       if (!selectedEditObj.otp) {
//         errorList.otp = 'Please enter valid OTP'
//       }
//     }

//     if (type === 'password') {

//       if (!selectedEditObj.password) {
//         errorList.password = 'Please enter your password'
//       }

//       if (!selectedEditObj.confirmPassword) {
//         errorList.confirmPassword = 'Please confirm your password'
//       } else if (selectedEditObj.password !== selectedEditObj.confirmPassword) {
//         errorList.confirmPassword = 'Passwords do not match'
//       }
//     }

//     if (Object.keys(errorList).length == 0) {
//       setErrorObj({})
//       return true
//     } else {
//       setErrorObj(errorList)
//       errorList = {}
//       return false
//     }
//   };

//   const varifyOtp = () => {
//     if (!validateForm(fieldState)) return
//     // setLoading(true);
//     // setFieldState('password');
//     verifyPassword({ data: { 'email': selectedEditObj.email, otp: selectedEditObj.otp } }).then((data: any) => {
//       sendMessage({
//         message: data?.data.message || '',
//         type: 'success',
//       });
//       setNewToken(data?.data?.token);

//       if (type === 'loginWithOtp') {
//         dispatch({
//           type: SET_TOKEN,
//           payload: data?.data?.token
//         });
//         return
//       };
//       setFieldState('password');
//     }).catch((error) => {
//       sendMessage({
//         message: error.response?.data?.message,
//         type: 'error',
//       });
//     })
//   };


//   const sendOtp = () => {
//     if (!validateForm(fieldState)) return
//     //
//     onSendOtp({ data: { 'email': selectedEditObj.email } }).then((data: any) => {
//       sendMessage({
//         message: data?.data.message || '',
//         type: 'success',
//       });
//       setFieldState('otp');
//     }).catch((error) => {
//       sendMessage({
//         message: error.response?.data?.message,
//         type: 'error',
//       });
//     })
//     // call api
//   };

//   const checkPassword = () => {
//     if (!validateForm(fieldState)) return
//     //
//     onChangePassword({ data: { 'password': selectedEditObj.password, 'otp': selectedEditObj.otp },
//     headers:{
//       Authorization: `Bearer ${newToken}`
//     }
//   }).then((data: any) => {
//       sendMessage({
//         message: data?.data.message || '',
//         type: 'success',
//       });
//       replace('/account/signin');

//     }).catch((error) => {
//       sendMessage({
//         message: error.response?.data?.message,
//         type: 'error',
//       });
//     })
//   }


//   const getContent = useMemo(() => {
//     if (fieldState === 'email') {
//       return <>
//         <Space style={{ justifyContent: 'center', alignItems: 'center' }}>
//           <Text style={sx({ fontSize: 18, fontWeight: '700', color: '#716d6d' })}>
//             Forgot Password
//           </Text>
//         </Space>

//         <Space style={sx({ justifyContent: 'center', alignItems: 'center', marginBottom: 10, marginTop: 50 })}>
//           <Text style={sx({ fontSize: 14, fontWeight: '500', color: '#716d6d' })}>
//             Enter Email Address
//           </Text>
//         </Space>

//         <Space style={{ marginBottom: 20 }}>
//           <TextField
//             innerInputLabel={false}
//             style={{
//               width: '100%',
//             }}
//             inputStyle={{
//               height: 50,
//               paddingLeft: 10
//             }}
//             placeholder="Enter Email"
//             onChangeText={(value) => updateValue('email', value)}
//             helperText={errorObj.email}
//             error={!!errorObj.email}
//           />
//         </Space>
//         <Space style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
//           <Link
//             variantType="text"
//             isNextLink
//             href="/account/signin"
//             style={sx({
//               width: [120],
//             })}
//             textColor="#999"
//           >
//             Back to Sign in
//           </Link>
//         </Space>
//       </>
//     } else if (fieldState === 'otp') {
//       return <>
//         <Space style={{ justifyContent: 'center', alignItems: 'center' }}>
//           <Text style={sx({ fontSize: 18, fontWeight: '700', color: '#716d6d' })}>
//             Enter Verification Code
//           </Text>
//         </Space>

//         <Space style={sx({ justifyContent: 'center', alignItems: 'center', marginBottom: 10, marginTop: 50 })}>
//           <Text style={sx({ fontSize: 14, fontWeight: '500', color: '#716d6d' })}>
//             Enter Otp
//           </Text>
//         </Space>
//         <Space style={{ marginBottom: 20, height: 70 }}>
//           <TextField
//             style={{
//               width: '100%',
//             }}
//             inputStyle={{
//               height: 50,
//               paddingLeft: 10
//             }}
//             keyboardType="number-pad"
//             placeholder="Enter Otp"
//             onChangeText={(value) => updateValue('otp', value)}
//             helperText={errorObj.otp}
//             error={!!errorObj.otp}
//           />
//         </Space>
//         <Space style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
//           <Link
//             variantType="text"
//             isNextLink
//             href="/account/signin"
//             style={sx({
//               width: [120],
//             })}
//             textColor="#999"
//           >
//             Back to Sign in
//           </Link>
//         </Space>
//       </>
//     } else if (fieldState === 'password') {
//       return <>
//         <Space style={{ justifyContent: 'center', alignItems: 'center' }}>
//           <Text style={sx({ fontSize: 18, fontWeight: '700', color: '#716d6d' })}>
//             New Password
//           </Text>
//         </Space>
//         <Space style={{ marginBottom: 20, height: 70, marginTop: 20 }}>
//           <TextField
//             style={{
//               width: '100%',
//             }}
//             inputStyle={{
//               height: 50,
//               paddingLeft: 10
//             }}
//             label="Enter New Password"
//             innerInputLabel
//             placeholder="Enter Password"
//             type="password"
//             onChangeText={(value) => updateValue('password', value)}
//             helperText={errorObj.password}
//             error={!!errorObj.password}
//           />
//         </Space>
//         <Space style={{ marginBottom: 20, height: 70, marginTop: 20 }}>
//           <TextField
//             style={{
//               width: '100%',
//             }}
//             inputStyle={{
//               height: 50,
//               paddingLeft: 10
//             }}
//             label="Confirm Password"
//             innerInputLabel
//             placeholder="Confirm Password"
//             type="password"
//             onChangeText={(value) => updateValue('confirmPassword', value)}
//             helperText={errorObj.confirmPassword}
//             error={!!errorObj.confirmPassword}
//           />
//         </Space>
//       </>
//     } else if (fieldState === 'changepassword') {
//       return <>
//         <Space style={{ justifyContent: 'center', alignItems: 'center' }}>
//           <Text style={sx({ fontSize: 18, fontWeight: '700', color: '#716d6d' })}>
//             Current Password
//           </Text>
//         </Space>
//         <Space style={{ marginBottom: 20, height: 70, marginTop: 20 }}>
//           <TextField
//             style={{
//               width: '100%',
//             }}
//             inputStyle={{
//               height: 50,
//               paddingLeft: 10
//             }}
//             label="Enter Current Password"
//             innerInputLabel
//             placeholder="Enter Password"
//             type="password"
//             onChangeText={(value) => updateValue('oldpassword', value)}
//             helperText={errorObj.oldpassword}
//             error={!!errorObj.oldpassword}
//           />
//         </Space>
//         <Space style={{ justifyContent: 'center', alignItems: 'center' }}>
//           <Text style={sx({ fontSize: 18, fontWeight: '700', color: '#716d6d' })}>
//             New Password
//           </Text>
//         </Space>
//         <Space style={{ marginBottom: 20, height: 70, marginTop: 20 }}>
//           <TextField
//             style={{
//               width: '100%',
//             }}
//             inputStyle={{
//               height: 50,
//               paddingLeft: 10
//             }}
//             label="Enter New Password"
//             innerInputLabel
//             placeholder="Enter Password"
//             type="password"
//             onChangeText={(value) => updateValue('password', value)}
//             helperText={errorObj.password}
//             error={!!errorObj.password}
//           />
//         </Space>
//         <Space style={{ marginBottom: 20, height: 70, marginTop: 20 }}>
//           <TextField
//             style={{
//               width: '100%',
//             }}
//             inputStyle={{
//               height: 50,
//               paddingLeft: 10
//             }}
//             label="Confirm Password"
//             innerInputLabel
//             placeholder="Confirm Password"
//             type="password"
//             onChangeText={(value) => updateValue('confirmPassword', value)}
//             helperText={errorObj.confirmPassword}
//             error={!!errorObj.confirmPassword}
//           />
//         </Space>
//       </>
//     } else {
//       return <></>
//     }

//   }, [fieldState, errorObj, type])

//   return (
//     <Layout
//       containerStyle={{
//         flex: 1,
//         width: '100%',
//         height: '100%',
//         padding: 20,
//         paddingTop: '20%'
//       }}
//       isProfileIcon={false}
//       isBackIcon={true}
//     >

//       {getContent}
//       <Button
//         style={sx({
//           height: 50,
//           display: 'flex',
//           backgroundColor: '$primary'
//         })}
//         loading={otpLoading || verifyLoading}
//         textColor="#000"
//         variantType="outlined"
//         onPress={() => {
//           if (fieldState === 'otp') {
//             varifyOtp()
//           } else if (fieldState === 'email') {
//             sendOtp()
//           } else if (fieldState === 'password') {
//             // 
//             checkPassword()
//           }
//         }}
//       >
//         <Text style={{ color: '#FFF' }}>
//           {fieldState === 'password' ? 'Verify' : 'Send'}
//         </Text>
//       </Button>

//       <Space style={{ justifyContent: 'center', alignItems: 'center' }}>
//         <Text>Don’t have an account?</Text>
//         <Link
//           variantType="text"
//           isNextLink
//           href="/account/signup"
//           style={sx({
//             width: [45, 'auto'],
//             marginLeft: 4,
//             paddingX: 0,
//           })}
//           textColor="#000"
//         >
//           Sign up
//         </Link>
//       </Space>
//     </Layout>
//   )
// }

