import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface ObjectDetectionContextState {
  pageStatus: 'upload-screen' | 'select-ai-models' | 'replace' | 'place' | 'teleport' | 'floor' |'wall';
  originalFile: any;
  imageObjectList: any[];
  pageId:string;
  rawImage:{},
  projectId:string
}

export const ObjectDetectionContext = createContext<{
  state: ObjectDetectionContextState;
  setState: Dispatch<SetStateAction<ObjectDetectionContextState>>;
  resetState: () => void;
}>({
  state: {
    pageStatus: 'upload-screen',
    originalFile: {},
    imageObjectList: [],
    pageId:'',
    rawImage:{},
    projectId:''
  },
  setState: () => {},
  resetState: () => {},
});

interface ObjectDetectionContextProviderProps {
  children: ReactNode;
}

export const ObjectDetectionContextProvider: React.FC<ObjectDetectionContextProviderProps> = ({ children }) => {
  const initialState: ObjectDetectionContextState = {
    pageStatus: 'upload-screen',
    originalFile: {},
    imageObjectList: [],
    pageId:'',
    rawImage:{},
    projectId:''
  };

  const [state, setState] = useState<ObjectDetectionContextState>(initialState);

  const resetState = () => {
    setState(initialState);
  };

  return (
    <ObjectDetectionContext.Provider
      value={{
        state,
        setState,
        resetState,
      }}
    >
      {children}
    </ObjectDetectionContext.Provider>
  );
};
