import { useSx } from 'dripsy';
import React from 'react';
import {
  FlatList,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { connect } from 'react-redux';
import * as removeToastAction from '../../redux/actions/toast.action';
import { ToastMessage } from '../../redux/reducers/toasts';
import { Icon } from '../icon/icon';

const Toast = ({
  toast,
  removeToast,
}: {
  toast: ToastMessage;
  removeToast?: (t: ToastMessage) => void;
}) => (
  <View
    style={[
      styles.toast,
      toast.type === 'info'
        ? styles.info
        : toast.type === 'warn'
        ? styles.warn
        : toast.type === 'error'
        ? styles.error
        : toast.type === 'success'
        ? styles.success
        : styles.unknown,
    ]}
  >
    <TouchableOpacity
      onPress={() => {
        if (removeToast) removeToast(toast);
      }}
    >
      <View
        style={{
          flex: 1,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {toast.type ? (
          <View style={{ marginRight: 8 }}>
            <Icon name={`${toast.type}`} fill={['#fff']} />
          </View>
        ) : (
          <></>
        )}
        <Text style={{ color: '#fff' }}>{toast.message}</Text>
      </View>
    </TouchableOpacity>
  </View>
);

const Toasts = ({
  toasts,
  removeToast,
}: {
  toasts: ToastMessage[];
  removeToast?: (t: ToastMessage) => void;
}) => {
  const sx = useSx();
  return (
    <>
      {toasts && toasts.length > 0 ? (
        <View
          style={[
            styles.toastContainer,
            sx({
              position: Platform.OS === 'web' ? 'fixed' : 'absolute',
            }),
          ]}
        >
          <FlatList
            data={toasts}
            keyExtractor={(item, index) => 'toast-wrapper' + index}
            renderItem={({ item, index }) =>
              item ? (
                <Toast
                  key={'toast-' + index}
                  toast={item}
                  removeToast={removeToast}
                ></Toast>
              ) : (
                <></>
              )
            }
          ></FlatList>
        </View>
      ) : (
        <></>
      )}
    </>
  );
};
const mapStateToProps = ({ toasts }: any) => ({ toasts });
export default connect(mapStateToProps, { ...removeToastAction })(Toasts);

const styles = StyleSheet.create({
  toast: {
    flex: 1,
    borderRadius: 10,
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 9999,
    padding: 20,
    margin: 10,
    alignItems: 'center',
  },
  unknown: {
    backgroundColor: '#313131',
  },
  success: {
    backgroundColor: '#43a047',
    color: '#fff',
  },
  error: {
    backgroundColor: '#d32f2f',
    color: '#fff',
  },
  info: {
    backgroundColor: '#2196f3',
    color: '#fff',
  },
  warn: {
    backgroundColor: '#ff9800',
    color: '#fff',
  },
  toastContainer: {
    alignSelf: 'center',
    right: 0,
    top: 30,
    margin: 10,
    width: 'auto',
    maxWidth: Platform.OS === 'web' ? '50%' : '100%',
    flex: 1,
    flexDirection: 'column-reverse',
  },
});
