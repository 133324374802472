import { initializeApp, getApps, FirebaseApp } from 'firebase/app';
import { getAnalytics, Analytics } from 'firebase/analytics';
import { Platform } from 'react-native';

const firebaseConfig = {
  apiKey: "AIzaSyCDTBC9Xv5xbz8cCAL-jymBQZtxVwKdlTo",
  authDomain: "gennoctua-64079.firebaseapp.com",
  projectId: "gennoctua-64079",
  storageBucket: "gennoctua-64079.appspot.com",
  messagingSenderId: "904065359696",
  appId: "1:904065359696:web:62f9457562e2f4aeab5655",
  measurementId: "G-BSJ86DCVY8"
};

let app: FirebaseApp | undefined;
let analytics: Analytics | undefined;


if (typeof window !== 'undefined' && Platform.OS == 'web') {
  // We're in a web environment
  if (!getApps().length) {
    app = initializeApp(firebaseConfig);
  }
  
  if (app) {
    analytics = getAnalytics(app);
  }
} else {
  // We're in a mobile or non-browser environment
  app = undefined;
  analytics = undefined;
}

export { app, analytics, };