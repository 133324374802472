import { makeUseAxiosHook } from 'app/redux/store';
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';


interface listDataType{
    data: any[];
    hasMore: boolean;
    refetch: boolean;
    lodeMore: boolean;
    refreshing:boolean;
    total:number
}

// Define types for the context
interface FriendContextType {
  friends: listDataType;
  friendRequests:listDataType;
  setFriends: (newFriends: listDataType) => void;
  setFriendRequests: (newFriendRequests: listDataType) => void;
  friendCount: number;
  setFriendCount: (count: number) => void;
  friendRequestCount: number;
  setFriendRequestCount: (count: number) => void;
}

// Create the context with default values
const FriendContext = createContext<FriendContextType | undefined>(undefined);

// Create a provider component
interface FriendProviderProps {
  children: ReactNode;
}

export const FriendProvider: React.FC<FriendProviderProps> = ({ children }) => {
    const {user} = useSelector((state: any) => ({ user:state.user }))
  const [friends, setFriends] = useState<listDataType>({
    data: [],
    hasMore: true,
    refetch: true,
    lodeMore: false,
    refreshing:false,
    total:0
  });
  const [friendRequests, setFriendRequests] = useState<listDataType>({
    data: [],
    hasMore: true,
    refetch: true,
    lodeMore: false,
    refreshing:false,
    total:0
  });
  const [friendCount, setFriendCount] = useState<number>(0);
  const [friendRequestCount, setFriendRequestCount] = useState<number>(0);

  const [{}, getFriendRequest] = makeUseAxiosHook('default', {
    manual: true,
  })({
    url: `/api/v1/friend-request?$sort[createdAt]=-1&$limit=50`,
    method: 'GET',
  })


  useEffect(()=>{
    if(!user?._id) return
    if (friendRequests?.refetch) {
      getFriendRequest({
        params: {
          $limit: 10,
          $skip: friendRequests?.data?.length,
        },
      }).then((res) => {
        setFriendRequestCount(res.data.total)
        setFriendRequests({
          data: friendRequests.lodeMore
            ? [...friendRequests.data, ...res.data.data]
            : res.data.data,
          total: res.data.total,
          hasMore: res.data.hasMore,
          refetch: false,
          lodeMore: false,
          refreshing:false
        })
      })
    }
  },[friendRequests?.refetch,user])
  
  return (
    <FriendContext.Provider value={{
      friends,
      friendRequests,
      setFriends,
      setFriendRequests,
      friendCount,
      setFriendCount,
      friendRequestCount,
      setFriendRequestCount
    }}>
      {children}
    </FriendContext.Provider>
  );
};

// Custom hook to use the FriendContext
export const useFriendContext = (): FriendContextType => {
  const context = useContext(FriendContext);
  if (context === undefined) {
    throw new Error('useFriendContext must be used within a FriendProvider');
  }
  return context;
};
