import React, { createContext, useState } from 'react'

type ProviderValue = {
  currentRoute: any
  generateCurrentRoute: any
}

export const CurrentRoutePath = createContext<ProviderValue>({
  currentRoute: null,
  generateCurrentRoute: (d: any) => d,
})

const CurrentRouteProvider = ({ children }) => {
  const [currentRoute, setCurrentRoute] = useState<ProviderValue>()
  return (
    <>
      <CurrentRoutePath.Provider
        value={{ currentRoute, generateCurrentRoute: setCurrentRoute }}
      >
        {children}
      </CurrentRoutePath.Provider>
    </>
  )
}

export default CurrentRouteProvider
