import { Text, useSx } from 'dripsy';
import React from 'react';
import { View, ViewStyle, TextStyle, TextProps } from 'react-native';

interface HeadingProps {
    children: React.ReactNode;
    containerStyle?: ViewStyle;
    sidebarStyle?: ViewStyle;
    textStyle?: TextStyle;
    textProps?:TextProps
}

export const Heading = ({ children, containerStyle, sidebarStyle, textStyle,textProps={} }: HeadingProps) => {
    const sx = useSx();
    return (
        <View
            style={[sx({
                // marginTop: 20,
                minHeight: 25,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom:10,
            }), containerStyle]}
        >
            <Text style={[sx({ marginLeft: 15, fontWeight: 'bold', fontSize: 16 }), textStyle]} 
              adjustsFontSizeToFit={true}
              maxFontSizeMultiplier={1.5}
            {...textProps}
            
            >{children}</Text>
        </View>
    );
};