import { config } from 'app/redux/config';
import {
  LOGIN_SUCCESS,
  SET_TOKEN,
  USER_REFRESH,
} from 'app/redux/constants';
import { Platform } from 'react-native';
import { setAsyncStorageData, setCookie } from 'app/util/helper';

//set from cookie or async storage
const setToken = async (token: string) => {
  if (Platform.OS === 'web' && typeof window !== 'undefined') {
    // token='eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJmaXJzdE5hbWUiOiJTbmVoYSIsImxhc3ROYW1lIjoiUSIsInJvbGUiOjEsInVzZXJuYW1lIjoiMTgwMDc4OTAzNDkiLCJpYXQiOjE3MDAwMzU4MDQsImF1ZCI6Imh0dHBzOi8vYWNjb3VudHMubG5wcmVwcm9kLmluIiwiaXNzIjoiZmVhdGhlcnMiLCJzdWIiOiI2NTE1NDgyYTM5YWRjZDAwMmViNmZlMWQiLCJqdGkiOiI4MjVkYjMwYy1mMzNjLTQ5ZmUtYmJlMC0xM2ZkZDAwY2Q0MTAifQ.LUhtu5wCky_0ZX-Ye9Uv7TAKrR0A0nvvYxwgwOhMNV4';
    setCookie(token);
    await setAsyncStorageData('userLoggedIn', 'true');
  } else {
    await setAsyncStorageData(config.authTokenName, token);
  }
};

const initialState = '';

const tokenStore = (
  state = initialState,
  action: {
    type: any;
    payload: any;
    asyncDispatch: (arg0: {
      type: string;
      payload: {
        client: string;
        request: { url: string; method: string; headers?: any };
      };
    }) => void;
  }
) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      setToken(action.payload.data.accessToken); // set from cookie or async storage
      setTimeout(
        () =>
          action.asyncDispatch({
            type: USER_REFRESH,
            payload: {
              client: 'default',
              request: {
                url: 'api/v1/me',
                method: 'GET',
                // headers: {
                //   Authorization: action.payload
                // }
              },
            },
          }),
        5
      );
      return action.payload.data.accessToken;
    }
    case SET_TOKEN: {
      try {
        setToken(action.payload);
      } catch (e) {} // set from cookie or async storage
      setTimeout(
        () =>
          action.asyncDispatch({
            type: USER_REFRESH,
            payload: {
              client: 'default',
              request: {
                url: 'api/v1/me',
                method: 'GET',
                // headers: {
                //   Authorization: action.payload
                // }
              },
            },
          }),
        5
      );

      return action.payload;
    }
    // case LOGOUT_SUCCESS: {
    //   // setTimeout(userLogout, 5);
    //   return initialState;
    // }
    // case 'LOGOUT_FAIL': {
    //   // setTimeout(userLogout, 5);
    //   return initialState;
    // }
    default: {
      return state;
    }
  }
};

export default tokenStore;
