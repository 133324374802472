import { createSelector } from "@reduxjs/toolkit";

export const _getUser = (state: { user: any; }) => ({user: state.user});
export const _getToken = (state: { token: any; }) => ({token: state.token});
export const _getLoaded = (state: { loaded: any; }) => ({loaded: state.loaded});
export const _getGlobal = (state: { globalStore: any; }) => ({globalStore: state.globalStore });

export const _getClientConfig = (state: { clientConfig: any; }) => ({clientConfig: state.clientConfig});

export const getUser = createSelector(_getUser, (user) => user);
export const getToken = createSelector(_getToken, (token) => token);
export const getLoaded = createSelector(_getLoaded, (loaded) => loaded);
export const getGlobal = createSelector(_getGlobal, (globalStore) => globalStore);

export const getClientConfig = createSelector(_getClientConfig, (clientConfig) => clientConfig);

