
// const url="http://192.168.155.52:3030"// my mobile net
// const url = 'http://192.168.0.172:3030' // yspl
// const url = 'http://192.168.31.6:3030' // yspl jio
const url='https://gennoctua.yoctotta.com';
//  const url='https://gennoctua.com';
const baseUrl= url
export const config = { 
   baseUrl:  process.env.NEXT_PUBLIC_BASE_URL || `${baseUrl}`,
   authTokenName:  process.env.NEXT_PUBLIC_AUTH_TK_NAME || 'gennoctuatkt',
   cookie:{
       domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || 'localhost'
   },
   expoConfig:{
    projectId:"466ccb57-48f7-48fb-a6c8-a0e0d9b4c585"
   },
   expoClientId:'GOOGLE_GUID.apps.googleusercontent.com', 
   googleWebClientId:  process.env.NEXT_PUBLIC_GOOGLE_WEB_CID || '129859122012-lv6ildls0lc2cg3omvb3j4e0sfj6hkfr.apps.googleusercontent.com',
   googleAndroidClientId:  process.env.NEXT_PUBLIC_GOOGLE_ANDROID_CID || '129859122012-qqaqf8t3tfr1m5ulnuoilj2n9m00g5g7.apps.googleusercontent.com',
   googleIOSClientId: process.env.NEXT_PUBLIC_GOOGLE_IOS_CID || '129859122012-qqaqf8t3tfr1m5ulnuoilj2n9m00g5g7.apps.googleusercontent.com',
 };