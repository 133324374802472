import React from 'react'
import { analytics, crashlytics } from './firebase'
import { Platform } from 'react-native'
import { connect } from 'react-redux'

class _ErrorBoundary extends React.Component<any, any, any> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true }
  }

  componentDidCatch(error: any, info: { componentStack: any }) {
    // console.error('componentDidCatch', error, info.componentStack)
    const { user } = this.props
    analytics().logEvent('error', {
      error: info.componentStack,
      // timestamp:  new Date().toISOString(),
      userName: user?.name,
      userId: user?._id,
      deviceType: Platform.OS,
    })
    if (Platform.OS !== 'web') {
      crashlytics().log(`error stack: ${info.componentStack}`)
    }
  }

  render() {
    // if (this.state.hasError) {
    //   return this.props.fallback;
    // }

    return this.props.children
  }
}

const mapStateToProps = ({ user, loaded, clientConfig }: any) => ({
  user,
})

export const ErrorBoundary = connect(mapStateToProps, {})(_ErrorBoundary)
