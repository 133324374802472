import { UPDATE_APP_CONFIG } from '../constants';

const initialState = {
name:'GENNOCTUA',
logoIcon:require('../../assets/logo.png'),
};
const clientConfig = (
  state =initialState,
  action: {
    payload: any; type: any 
}
) => {
  switch (action.type) {
    case UPDATE_APP_CONFIG:
      return { ...state,...action.payload };
    default:
      return state;
  }
};

export default clientConfig;
