import useOnlineStatus from 'app/redux/hooks/useOnlineStatus';
import React, { useEffect, useState } from 'react';
import Dialog from '../dialog';
import { View } from 'dripsy';
import { Button } from '../button';
import DialogAction from '../dialog/dialog-action';
import { H2 } from '../typography/h2';
import { Icon } from '../icon/icon';
import { BackHandler, Platform } from 'react-native';
import { P } from '../typography/p';

export const Offline = () => {
    const isOnline = useOnlineStatus();
    const [isOffline, setOffline] = useState(false);

    useEffect(() => {
        setOffline(!isOnline);
    }, [isOnline]);

    return (
        <Dialog
            modalVisible={isOffline}
            // onCloseModal={() => setOpen(false)}
            styles={{
                borderRadius: 5,
                minWidth: 300,
                maxWidth: 300,
                padding: 15

            }}
        >
            <View
                style={{
                    minHeight: 120,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Icon name='noInternet' />
                <H2>{'You\'re Offline'}</H2>
                <P>Check your internet connections, then try agin.</P>
            </View>

            <DialogAction
            style={{
                justifyContent:'flex-end',
            }}
            >
             {  Platform.OS == 'android'? <Button
                    style={{
                      width:100,
                      height:25
                    }}
                    textColor={'grey'}
                    variantColor="primary"
                    variantType="outlined"
                    onPress={() => {
                        if(Platform.OS == 'android'){
                            BackHandler.exitApp()
                        }
                    }}
                >
                    Exit
                </Button>:<></>}
                <Button
                    style={{
                      width:100,
                      height:25
                    }}
                    textColor={'grey'}
                    variantColor="primary"
                    variantType="outlined"
                    onPress={() => {
                        setOffline(false);
                    }}
                >
                    Ok
                </Button>
            </DialogAction>
        </Dialog>
    );
};
