import { useSx, View, Row } from 'dripsy';
import React from 'react';
import { Button } from '../button';
import { ViewStyle } from 'react-native';
import { Icon } from '../icon/icon';
import { H4 } from '../typography/h4';
import { Heading } from '../typography/heading';

interface DialogTitleProps {
  children?: React.ReactNode;
  onClose?: (e: boolean) => void;
  styles?: any;
  divider?: boolean;
  extra?: any;
  styleContainer?: ViewStyle;
}

export default function DialogTitle({
  children,
  onClose,
  styles,
  divider = false,
  extra,
  styleContainer,
}: DialogTitleProps) {
  const sx = useSx();
  return (
    <Row
      style={[
        sx({
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
          borderBottomWidth: divider ? 1 : 0,
          borderStyle: 'solid',
          marginBottom: 3,
          borderColor:'#5d5d5d'
        }),
        styleContainer,
      ]}
    >
      {typeof children === 'string' ? (
         <Heading
          containerStyle={{
            display:'flex',
            height:'100%',
            width:'100%',
            alignItems:'center',
            marginBottom:0,
            paddingTop:10,
            paddingBottom:10
          }}
          textStyle={{ 
            textAlign:'left',
            alignSelf:'center'
           }}
         >
          {children}
         </Heading>
      ) : (
        <View
          style={[
            sx({
              flex: 1,
            }),
            styles,
          ]}
        >
          {children}
        </View> 
      )}
      {extra ? extra : <></>}
      {onClose ? (
        <Button
          style={[
            sx({
              backgroundColor: '$white',
              borderRadius: 50,
              paddingY: 0,
              paddingX: 0,
              minWidth: 25,
              minHeight: 25
            }),
          ]}
          onPress={() => onClose && onClose(false)}
        >
          <Icon name="cross" height={15} width={15} />
        </Button>
      ) : (
        <></>
      )}
    </Row>
  );
}

//
