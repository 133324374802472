import { uuidv4 } from 'app/util/helper';
import React, { createContext, useContext, useReducer } from 'react';
import { View, Text } from 'react-native';
import Dialog from '../../../components/dialog';
import { Button } from '../../../components/button';
import DialogTitle from '../../../components/dialog/dialog-titel';
import { useSx } from 'dripsy';
import DialogAction from '../../../components/dialog/dialog-action';

export interface AlertBody {
  id: string;  // Make 'id' a required property
  type: 'warn' | 'error' | 'info';
  message: string;
  title: string;
  buttons: { name: string; buttonStyle?: any; onPress?: (v: string, callback: () => void) => void; closedManual?: boolean }[];
}

type AlertAction =
  | { type: 'add'; data: Omit<AlertBody, 'id'> }  // id will be generated
  | { type: 'remove'; id: string };

const AlertContext = createContext<(body: Omit<AlertBody, 'id'>) => void>(() => {});

export const useAlert = () => {
  return useContext(AlertContext);
};

const alertReducer = (state: AlertBody[], action: AlertAction): AlertBody[] => {
  switch (action.type) {
    case 'add':
      return [...state, { ...action.data, id: uuidv4() }];
    case 'remove':
      return state.filter(alert => alert.id !== action.id);
    default:
      return state;
  }
};

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const sx = useSx();
  const [alerts, dispatch] = useReducer(alertReducer, []);

  return (
    <AlertContext.Provider value={(b: Omit<AlertBody, 'id'>) => dispatch({ type: 'add', data: b })}>
      <View style={{ flex: 1 }}>
        {children}
        {alerts.map((alert, index) => (
          <Dialog
            key={alert.id}
            modalVisible={true}
            onCloseModal={() => dispatch({ type: 'remove', id: alert.id })}
            styles={sx({
              minHeight: 80,
              width: [350, 400, 500],
              justifyContent: 'space-between',
            })}
          >
            <DialogTitle divider>
              {alert.title}
            </DialogTitle>
            <View style={sx({
              minHeight: 80,
              padding: 10,
              justifyContent: 'space-between',
            })}>
              <Text     adjustsFontSizeToFit={true}
              maxFontSizeMultiplier={1.5} style={{ textAlign: 'left', lineHeight: 21 }}>{alert.message}</Text>
              <DialogAction>
                {alert.buttons?.map((button, buttonIndex) => (
                  <Button
                    key={`${alert.id}-but-${buttonIndex}`}
                    onPress={() => {
                      const manualCancel = () => dispatch({ type: 'remove', id: alert.id });
                      if (button.closedManual) {
                        button.onPress?.(button.name, manualCancel);
                      } else {
                        button.onPress?.(button.name, manualCancel);
                        dispatch({ type: 'remove', id: alert.id });
                      }
                    }}
                    textStyle={{
                      color: button.buttonStyle?.color || 'white',
                      fontSize: 15,
                    }}
                    style={[
                      {
                        width: 85,
                        height: 30,
                      },
                      button.buttonStyle,
                    ]}
                  >
                    {button.name}
                  </Button>
                ))}
              </DialogAction>
            </View>
          </Dialog>
        ))}
      </View>
    </AlertContext.Provider>
  );
};
