import React from 'react';
import { Image,  View } from 'react-native';
interface LoaderProps {
  title?: string;
  children?: any;
}

export const Loader = ({ children }: LoaderProps) => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFF',
        width: 'auto',
        height: '100%',
      }}
    >
      <Image
        source={require('../../assets/logo-bg.png')}
        defaultSource={require('../../assets/logo-bg.png')}
        resizeMode='contain'
        style={{
          width:300,
          height: 300,
          alignSelf: 'center',
          justifyContent: 'center',
        }}
        alt='logo'
      />
      {/* <Text>{title}</Text> */}
      {children}
    </View>
  );
};
