
const initialState = {

};

const userNotifications = (
  state = initialState,
  action: {
    type: any;
    payload: { data: { data: any } };
    error: { response: any; data: any };
  }
) => {

  switch (action.type) {
    case 'GET_USER_NOTIFICATIONS_SUCCESS': {
      return action.payload.data;
    }
    case 'GET_USER_NOTIFICATIONS_UPDATE': {
      return { ...state, ...action.payload?.data };
    }

    default: {
      return state;
    }
  }
};

export default userNotifications;
