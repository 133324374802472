import React from 'react'
import { Dripsy } from './dripsy'
import { NavigationProvider } from './navigation'
import { Provider as RDProvider } from 'react-redux'
import { store } from 'app/redux/store'
import Toasts from '../components/toasts/toast'
import { AlertProvider } from 'app/redux/hooks/alert'
import CurrentRouteProvider from './current-route-name'
import { Offline } from 'app/components/offline/offline'
import { Platform } from 'react-native'
import { ObjectDetectionContextProvider } from 'app/features/image-objects-manage/image-objects-provider'
import { ReplaceContextProvider } from 'app/features/ai-models/replace-object/object-replace-provider'
import { PlaceContextProvider } from 'app/features/ai-models/place-object/object-place-provider'
import { TeleportContextProvider } from 'app/features/ai-models/teleport-object/object-teleport-provider'
import { NotificationProvider } from './notification-context'
import { FriendProvider } from './friend-context'
import { ErrorBoundary } from './error-boundary'
import { I18Provider } from './i18-provider'

export function Provider({ children }: { children: React.ReactNode }) {
  return (
    <RDProvider store={store}>
      <ErrorBoundary>
        <Dripsy>
        <I18Provider>
          <CurrentRouteProvider>
            <NavigationProvider>
              <AlertProvider>
                <FriendProvider>
                <NotificationProvider>
                <ObjectDetectionContextProvider>
                  <PlaceContextProvider>
                    <ReplaceContextProvider>
                      <TeleportContextProvider>
                      {children}
                      </TeleportContextProvider>
                    </ReplaceContextProvider>
                  </PlaceContextProvider>
                </ObjectDetectionContextProvider>
                </NotificationProvider>
                </FriendProvider>
              </AlertProvider>
              <Toasts />
              {Platform.OS !== 'web' && <Offline />}
            </NavigationProvider>
          </CurrentRouteProvider>
          </I18Provider>
        </Dripsy>
      </ErrorBoundary>
    </RDProvider>
  )
}
