import { useSx, View, Text, ActivityIndicator, useDripsyTheme } from 'dripsy'
import {
  MotiPressable as DripsyMotiPressable,
  MotiPressableProps,
} from 'moti/interactions'
import React, { ReactNode, useMemo, useState } from 'react'
import { ViewStyle } from 'react-native'

export interface ButtonProps extends MotiPressableProps {
  variantColor?: 'primary' | 'secondary' | 'light'
  startIcon?: ReactNode
  endIcon?: ReactNode
  loading?: boolean
  variantType?: 'contained' | 'outlined' | 'text'
  iconColor?: string
  textColor?: string
  startIconStyle?: ViewStyle
  textStyle?: any
  loadingIndicatorSize?: number
  animatePressable?: ({
    pressed,
    hovered,
  }: {
    pressed: boolean
    hovered: boolean
  }) => any
  contentStyle?: ViewStyle
  endIconStyle?: ViewStyle
  onHoveredTextColor?: string;
  btnBgColor?:string
}

export const Button = ({
  style,
  variantColor = 'primary',
  disabled = false,
  loading = false,
  startIcon,
  endIcon,
  children,
  variantType = 'contained',
  iconColor,
  textColor,
  textStyle = {},
  startIconStyle,
  loadingIndicatorSize = 25,
  animatePressable,
  contentStyle,
  endIconStyle,
  onHoveredTextColor,
  btnBgColor,
  ...props
}: ButtonProps) => {
  const sx = useSx()
  const [btnHovered, setBtnHovered] = useState<boolean>(false)
  const hoverStyle = useMemo(() => {
    if (animatePressable) {
      return animatePressable({ pressed: true, hovered: true })
    } else {
      return {}
    }
  }, [animatePressable, variantType])
  const nonHoverStyle = useMemo(() => {
    if (animatePressable) {
      return animatePressable({ pressed: false, hovered: false })
    } else {
      return {}
    }
  }, [animatePressable, variantType])

  const bgColor = useMemo(() => {
    if(btnBgColor){
        return btnBgColor
    }else if (variantColor === 'primary') {
      return '$primary'
    } else if (variantColor === 'light') {
      return '$white'
    } else if (variantColor === 'secondary') {
      return '$secondary'
    } else {
      return '$secondary'
    }
  }, [variantColor,btnBgColor])

  const { theme } = useDripsyTheme()
  const variantStyles = useMemo(() => {
    const styleList: any = {
      contained: {
        backgroundColor: bgColor,
        color: '#fff',
      },
      'contained-disabled': {
        backgroundColor: '$disabled',
        color: '#000000',
        borderColor: '$disabled',
        cursor: 'not-allowed',
        borderWidth: 1,
      },
      outlined: {
        borderColor: bgColor,
        color: bgColor,
        borderWidth: 1,
        borderStyle: 'solid',
      },
      'outlined-disabled': {
        borderColor: '$disabled',
        color: '$disabled',
        borderWidth: 1,
        borderStyle: 'solid',
        cursor: 'not-allowed',
      },
      text: {
        color: bgColor,
      },
      'text-disabled': {
        color: '#999990',
        cursor: 'not-allowed',
      },
    }

    return disabled || loading
      ? styleList[`${variantType}-disabled`]
      : styleList[variantType]
  }, [variantType, disabled, loading, bgColor])
  const isString = typeof children === 'string' || 
  (React.isValidElement(children) && typeof children.props.children === 'string');

  const _renderButton = () => (
    <>
      {startIcon && !loading ? (
        <View
          style={[
            sx({
              marginRight: 1,
            }),
            startIconStyle,
          ]}
        >
          {startIcon}
        </View>
      ) : null}
      <View
        style={[
          sx({
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
          }),
          contentStyle,
        ]}
      >
        <>
          {loading && (
            <ActivityIndicator
              style={sx({ marginRight: 2, marginLeft: 2 })}
              size={loadingIndicatorSize}
              color="rgb(28 118 186 / 50%)"
            />
          )}
          {isString ? (
            <Text
              style={[
                sx({
                  zIndex: 2,
                  color: variantStyles.color,
                }),
                textColor ? { color: textColor } : {},
                { fontSize: theme?.text?.btnText },
                textStyle,
                btnHovered && onHoveredTextColor
                  ? { color: onHoveredTextColor }
                  : {},
              ]}
              numberOfLines={1}
              ellipsizeMode={'tail'}
              adjustsFontSizeToFit={true}
              maxFontSizeMultiplier={1.5}
            >
              {children}
            </Text>
          ) : (
            children
          )}
        </>
      </View>

      {endIcon && !loading ? (
        <>
          <View
            style={[
              sx({
                marginRight: 1,
              }),
              endIconStyle,
            ]}
          >
            {endIcon}
          </View>
        </>
      ) : null}
    </>
  )

  return (
    <DripsyMotiPressable
      disabled={disabled || loading}
      onHoverIn={() => setBtnHovered(true)}
      onHoverOut={() => setBtnHovered(false)}
      animate={useMemo(
        () =>
          ({ hovered, pressed }) => {
            'worklet'
            return {
              opacity: hovered || pressed ? 0.5 : 1,
              ...(hovered || pressed ? hoverStyle : nonHoverStyle),
            }
          },
        [hoverStyle, nonHoverStyle]
      )}
      accessibilityRole="button"
      style={[
        sx({
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 5,
          paddingX: 10,
          paddingY: 2,
          margin: 1,
          ...variantStyles,
        }),
        style,
      ]}
      {...props}
    >
      {_renderButton()}
    </DripsyMotiPressable>
  )
}
