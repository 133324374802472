import React, { createContext, useState, useEffect, useRef, useContext, ReactNode } from 'react';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { app,analytics } from './firebase'
import { ACCEPTING_FRIEND_REQUEST, DELETING_FRIEND_REQUEST, EXPO_NOTIFICATION_TOKEN, RECEIVING_FRIEND_REQUEST,FIREBASE_NOTIFICATION_TOKEN } from 'app/redux/constants';
import { useFriendContext } from './friend-context';
import { config } from 'app/redux/config';
import { FirebaseApp } from 'firebase/app';
import { Analytics } from 'firebase/analytics';


// Notification Handler
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

interface NotificationContextProps {
  expoPushToken: string;
  channels: Notifications.NotificationChannel[];
  notification?: Notifications.Notification;
  appFirebase?:FirebaseApp;
  firebaseAnalytics?:Analytics
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { setFriendRequests,friendRequests } = useFriendContext()
  const [expoPushToken, setExpoPushToken] = useState<string>('');
  const [channels, setChannels] = useState<Notifications.NotificationChannel[]>([]);
  const [notification, setNotification] = useState<Notifications.Notification | undefined>(undefined);
  const notificationListener = useRef<Notifications.Subscription | undefined>();
  const responseListener = useRef<Notifications.Subscription | undefined>();

  // Initialize Firebase
  const [appFirebase, setFirebaseApp] = useState<FirebaseApp>();
  const [firebaseAnalytics, setFirebaseAnalytics] = useState<Analytics>();
  // const [firebaseCrashlytics, setFirebaseCrashlytics] = useState<any>('');

  useEffect(() => {
    if(Platform.OS == 'web'){
      setFirebaseApp(app as any)
      setFirebaseAnalytics(analytics as any)
    }else{
      registerForPushNotificationsAsync().then(token => {
        if (token) setExpoPushToken(token);
      });
      
      if (Platform.OS === 'android') {
        Notifications.getNotificationChannelsAsync().then(setChannels);
      }
  
      notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
        switch (notification?.request?.content?.data?.status) {
          case DELETING_FRIEND_REQUEST:
          case  ACCEPTING_FRIEND_REQUEST:
          case RECEIVING_FRIEND_REQUEST:
            setFriendRequests({
              ...friendRequests,
              refetch: true,
              data: [],
            });
            break;
        
          default:
            break;
        }
        setNotification(notification);
      });
  
      responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
        // console.log('responseListener 2', response);
      });
  
    }
    return () => {
      if (notificationListener.current) {
        Notifications.removeNotificationSubscription(notificationListener.current);
      }
      if (responseListener.current) {
        Notifications.removeNotificationSubscription(responseListener.current);
      }
      // unsubscribe();
    };
  }, []);


  const registerForPushNotificationsAsync = async (): Promise<string | undefined> => {
    if(Platform.OS === 'web') return ''
      let token: any;
      if(typeof window === 'undefined') return
         // Check if the token already exists in AsyncStorage
    try {
      token = await AsyncStorage.getItem(EXPO_NOTIFICATION_TOKEN);
      if (token) {
        // console.log('Push token already stored:', token);
        return token;
      }
    } catch (e) {
      console.error('Failed to fetch push token from storage:', e);
    }

    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }     
      try {
        const projectId = Constants.expoConfig?.extra?.eas?.projectId  || config.expoConfig.projectId;
        if (!projectId) {
          throw new Error('Project ID not found');
        }
        token = (await Notifications.getExpoPushTokenAsync({ projectId })).data;

        // Store the token in AsyncStorage
        await AsyncStorage.setItem(EXPO_NOTIFICATION_TOKEN, token);
      } catch (e) {
        console.error('Failed to get push token:', e);
        token = `${e}`;
      }
    } else {
      alert('Must use physical device for Push Notifications');
    }
    return token;
  };


  return (
    <NotificationContext.Provider
      value={{
        expoPushToken,
        channels,
        notification,
        appFirebase,
        firebaseAnalytics
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = (): NotificationContextProps => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
