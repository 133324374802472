import React, { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface ObjectDetectionContextState {
  pageStatus: 'upload-screen' | 'object-list' | 'upload-reference-screen' | 'reference-object-list' | 'result-screen';
  originalFile: any;
  referenceFile: any;
  originalImagePoint: any;
  resultObj?: any;
  referenceImagePoint:any
}

export const TeleportContext = createContext<{
  teleportState: ObjectDetectionContextState;
  setTeleportState: Dispatch<SetStateAction<ObjectDetectionContextState>>;
  teleportStateReset: () => void;
}>({
  teleportState: {
    pageStatus: 'upload-screen',
    originalFile: {},
    referenceFile: {},
    originalImagePoint: {},
    resultObj: undefined,
    referenceImagePoint:{}
  },
  setTeleportState: () => {},
  teleportStateReset: () => {},
});

interface ObjectDetectionContextProviderProps {
  children: ReactNode;
}

export const TeleportContextProvider: React.FC<ObjectDetectionContextProviderProps> = ({ children }) => {
  const initialState: ObjectDetectionContextState = {
    pageStatus: 'upload-screen',
    originalFile: {},
    referenceFile: {},
    originalImagePoint: {},
    resultObj: undefined,
    referenceImagePoint:{}
  };

  const [teleportState, setTeleportState] = useState<ObjectDetectionContextState>(initialState);

  const teleportStateReset = () => {
    setTeleportState(initialState);
  };

  return (
    <TeleportContext.Provider
      value={{
        teleportState,
        setTeleportState,
        teleportStateReset,
      }}
    >
      {children}
    </TeleportContext.Provider>
  );
};
