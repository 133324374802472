export const UPDATE_APP_CONFIG='UPDATE_APP_CONFIG';

export const USER_REFRESH = 'USER_REFRESH';
export const USER_REFRESH_SUCCESS = 'USER_REFRESH_SUCCESS';
export const USER_REFRESH_FAIL = 'USER_REFRESH_FAIL';

export const UPDATE_USER_DATA='UPDATE_USER_DATA';

export const SET_TOKEN = 'SET_TOKEN';
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAIL';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const FETCH_USER = 'FETCH_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const CHECK_USER = 'CHECK_USER';
export const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS';
export const CHECK_USER_FAILURE = 'CHECK_USER_FAIL';
export const SEND_OTP = 'SEND_OTP';
export const OTP_LOGIN = 'OTP_LOGIN';
export const VERIFY_OTP = 'VERIFY_OTP';
export const RESET_PASSWORD = 'RESET_PASSWORD';


export const UPDATE_GLOBAL_STORE_DATA='UPDATE_GLOBAL_STORE_DATA';



export const EXPO_NOTIFICATION_TOKEN = 'EXPO_NOTIFICATION_TOKEN';
export const FIREBASE_NOTIFICATION_TOKEN = 'FIREBASE_NOTIFICATION_TOKEN';


// notification status

export const RECEIVING_FRIEND_REQUEST='RECEIVING_FRIEND_REQUEST';
export const ACCEPTING_FRIEND_REQUEST = 'ACCEPTING_FRIEND_REQUEST';
export const DELETING_FRIEND_REQUEST = 'DELETING_FRIEND_REQUEST';

