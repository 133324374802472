import { ScrollView, useSx, View } from 'dripsy';
import React, { useEffect } from 'react';
import { Platform, ScrollViewProps, ViewStyle } from 'react-native'; //ModalProps
import Modal from 'react-native-modal';
import { useDim } from 'app/redux/hooks/useDim';
interface DialogProps {
  modalVisible?: boolean;
  children?: React.ReactNode;
  styles?: any;
  contentContainerStyle?: ViewStyle;
  onCloseModal?: (e: boolean) => void;
  modalStyle?: ViewStyle;
  scrollViewProps?:ScrollViewProps;
  header?:any;
  avoidKeyboard?:boolean
}

export default function Dialog({
  modalVisible = false,
  children,
  styles = {},
  onCloseModal,
  contentContainerStyle,
  modalStyle = {},
  avoidKeyboard=false,
  header
}: DialogProps) {
  const sx = useSx();
  const { height, width,currentWidth } = useDim();

  useEffect(() => {
    if (Platform.OS === 'web') {
      if (modalVisible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }
    return () => {
      if (Platform.OS === 'web') document.body.style.overflow = 'unset';
    };
  }, [modalVisible]);

  return (
    <Modal
      animationIn="fadeIn"
      animationOut="fadeOut"
      avoidKeyboard={avoidKeyboard}
      onBackButtonPress={() => onCloseModal && onCloseModal(false)}
      onSwipeCancel={() => onCloseModal && onCloseModal(false)}
      onBackdropPress={() => onCloseModal && onCloseModal(false)}
      hasBackdrop={true}
      backdropColor="transparent"
      isVisible={modalVisible}
      style={[
        sx({
          justifyContent: 'center',
          alignItems: 'center',
          height,
          width:currentWidth,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          margin: 0,
        }),contentContainerStyle
      ]}
    >
      <View style={[modalStyle]}>
        <View
          style={[
            sx({
              backgroundColor: 'white',
              borderRadius: 8,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 4,
              elevation: 5,
              minWidth: 300,
              maxWidth: 500,
            }),
            styles,
          ]}
        >
          {header}
            {children}
        </View>
      </View>
    </Modal>
  );
}
